import { ThemeOptions } from '@mui/material';
import type {} from '@mui/x-data-grid-pro/themeAugmentation';
import { ListItem } from 'src/components/mui-components/ListItem';
import HighlightMatchingText from 'src/components/utils/HighlightMatchingText';
import { ListItemText } from 'src/components/mui-components';
import scssTypography from '../styles/variables/typography.module.scss';
import scssColor from '../styles/variables/colors/global/grays.module.scss';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    small: React.CSSProperties;
    table: React.CSSProperties;
    numeric: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    small?: React.CSSProperties;
    table?: React.CSSProperties;
    numeric?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    small: true;
    table: true;
    numeric: true;
  }
}

export const timeLogThemeOptions: ThemeOptions = {
  palette: {
    primary: {
      main: '#BF2574',
      contrastText: '#fff',
    },
    secondary: {
      main: '#BF2574',
      contrastText: '#fff',
    },
    error: {
      main: '#C92726',
      contrastText: '#fff',
    },
    warning: {
      main: '#DA8B01',
      contrastText: '#fff',
    },
    info: {
      main: '#3A66D5',
      contrastText: '#fff',
    },
    success: {
      main: '#007A00',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: scssTypography.fontFamily,
    fontSize: parseInt(scssTypography.fontSizeBody, 10),
    h1: {
      fontSize: parseInt(scssTypography.fontSizeDisplay, 10),
      fontWeight: 700,
    },
    h2: {
      fontSize: parseInt(scssTypography.fontSizeHeading, 10),
      fontWeight: 700,
    },
    h3: {
      fontSize: parseInt(scssTypography.fontSizeSubHeading, 10),
      fontWeight: 700,
    },
    h4: {
      fontSize: parseInt(scssTypography.fontSizeBody, 10),
    },
    body1: {
      fontSize: parseInt(scssTypography.fontSizeBody, 10),
    },
    body2: {
      fontSize: parseInt(scssTypography.fontSizeSubHeading, 10),
    },
    small: {
      fontSize: parseInt(scssTypography.fontSizeTips, 10),
    },
    table: {
      fontSize: parseInt(scssTypography.fontSizeBody, 10),
    },
    numeric: {
      fontFamily: scssTypography.fontFamilyNumeric,
    },
  },
  components: {
    MuiGrid: {
      defaultProps: {
        spacing: 2,
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          // Ignore the ts error, as it seems to be a bug in the MUI types
          // https://github.com/mui/material-ui/issues/21583
          // @ts-ignore
          component: 'h2',
          fontSize: parseInt(scssTypography.fontSizeSubHeading, 10),
          fontWeight: parseInt(scssTypography.fontWeightBold, 10),
        },
      },
    },
    MuiDataGrid: {
      defaultProps: {
        rowHeight: 40,
      },
      styleOverrides: {
        root: {
          fontFamily: scssTypography.fontFamily,
          fontSize: parseInt(scssTypography.fontSizeBody, 10),
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        container: {
          alignItems: 'start',
        },
        paper: {
          verticalAlign: 'top',
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: '0 24px 24px',
        },
      },
    },
    MuiStack: {
      defaultProps: {
        useFlexGap: true,
        gap: 1,
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
      },
    },
    MuiAutocomplete: {
      defaultProps: {
        renderOption: (props, option, state, ownerState) => (
          <ListItem {...props}>
            <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
              <HighlightMatchingText
                name={ownerState.getOptionLabel(option)}
                matchName={state.inputValue}
              />
            </ListItemText>
          </ListItem>
        ),
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          width: 'fit-content',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: scssColor.colorGray30,
        },
      },
    },
  },
};
