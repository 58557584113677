import { Box } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid-pro';
import {
  Button,
  DataGrid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from 'src/components/mui-components';
import { InfoOutlined } from '@mui/icons-material';

interface IViewTextDialog {
  showBookingAction: boolean;
  setShowBookingAction: (value: boolean) => void;
}

// Mock data, to be return from BE
const rows = [
  {
    id: 1,
    contract: 'Fastpris - Standardkontrakt',
    contractType: 'Fixed price - Standard contract',
    description: 'One or more payments have not been invoiced yet.',
  },
  {
    id: 2,
    contract: 'Fastpris - Standardkontrakt',
    contractType: 'Fixed price - Standard contract',
    description: 'The contract was closed without creating a related payment.',
  },
];

const columns: GridColDef[] = [
  { field: 'contract', headerName: 'Contract', flex: 1 },
  { field: 'contractType', headerName: 'Contract type', flex: 1 },
  {
    field: 'description',
    headerName: 'Description',
    flex: 2,
  },
];

export const BookingActionDialog = ({
  showBookingAction,
  setShowBookingAction,
}: IViewTextDialog) => (
  // const { t } = useTranslation('searchProject');

  <Dialog
    open={!!showBookingAction}
    onClose={() => setShowBookingAction(false)}
    aria-labelledby="dialog-text"
    aria-describedby="dialog-text-description"
    fullWidth
    maxWidth="lg"
  >
    <DialogTitle id="dialog-text">
      <Stack direction="row">
        Manage pending bookings
        <IconButton title="help" href="https://help.timelog.com/en" target="_blank">
          <InfoOutlined fontSize="small" />
        </IconButton>
      </Stack>
    </DialogTitle>
    <DialogContent>
      <Box sx={{ height: 400, width: '100%' }}>
        <DataGrid rows={rows} columns={columns} disableColumnTopBorder hideFooter />
      </Box>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={() => setShowBookingAction(false)}>
        Cancel{' '}
      </Button>
    </DialogActions>
  </Dialog>
);
