/* eslint-disable no-alert */
import { useEffect, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import {
  DataGrid,
  dataGridCellBorderLeftThickClassName,
  dataGridCellBorderRightThickClassName,
} from 'src/components/mui-components/DataGrid';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GridActionsCellItem,
  GridColDef,
  GridColumnGroupingModel,
  GridDensity,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-pro';
import { useFilterStore } from 'src/stores/FilterStore';
import { IconButton, Typography } from 'src/components/mui-components';
import { toggleFilterContainerEventName } from 'src/components/layout/FilterLayout/components/MainContainer/components/FilterContainer';
import { useTranslation } from 'react-i18next';
import { useGetSearchProjects } from 'src/apis/searchProjectsAPI';
import { Box, LinearProgress, Tooltip } from '@mui/material';
import {
  Archive,
  AssignmentTurnedIn,
  Delete,
  Description,
  EditNote,
  Edit,
  PlayArrow,
  Pause,
  Done,
  Close,
  InfoOutlined,
} from '@mui/icons-material';
import { formatTime } from 'src/utils/time';
import { SPSelectedFilterListStateKey } from '../../localStorageKeys';
import { Toolbar } from './Toolbar';

import styles from '../../SearchProjects.module.scss';
import { TimeRegistrationsDialog } from '../TimeRegistrationsDialog';
import { BookingActionDialog } from '../BookingActionDialog';

interface ISearchProject {
  selectedViewOptions: any;
}

const slots = (isRowSelected: boolean, columns: GridColDef[]) => ({
  toolbar: () => <Toolbar isRowSelected={isRowSelected} columns={columns} />,
});

const getColor = (value: number) => {
  if (value === 100) {
    return styles.progressInProgress;
  }
  if (value < 81) {
    return styles.progressPositive;
  }
  if (value < 100) {
    return styles.progressWarning;
  }
  return styles.progressNegative;
};

export const Table = ({ selectedViewOptions }: ISearchProject) => {
  const { t } = useTranslation('searchProjects');
  const siteLocale = useGetLocale();
  const { filterQueryObj } = useFilterStore();
  localStorage.setItem(SPSelectedFilterListStateKey, JSON.stringify(filterQueryObj));

  const {
    isLoading,
    isSuccess,
    isError,
    isEmpty,
    children: rows,
  } = useGetSearchProjects({ selectedFilterList: filterQueryObj }, selectedViewOptions);

  const [isRowSelected, setIsRowSelected] = useState(false);

  const handleRowSelectionChange = (newSelection: any) => {
    setIsRowSelected(newSelection.length > 0);
  };

  // To control the initial state and time registration indicator in actual column
  const [density, setDensity] = useState<GridDensity>('standard');

  // Time Registration Dialog
  const [showTimeRegistration, setShowTimeRegistration] = useState(false);

  // BookKeeping Dialog
  const [showBookingAction, setShowBookingAction] = useState(false);

  const statusValueOptions = [
    { label: t('StatusQuotationText'), value: 'quotation', icon: Description },
    { label: t('StatusApprovedText'), value: 'approved', icon: AssignmentTurnedIn },
    { label: t('StatusInProgressText'), value: 'inProgress', icon: PlayArrow },
    { label: t('StatusOnHoldText'), value: 'onHold', icon: Pause },
    { label: t('StatusCompletedText'), value: 'completed', icon: Done },
    { label: t('StatusArchivedText'), value: 'archived', icon: Archive },
    { label: t('StatusCancelledText'), value: 'cancelled', icon: Close },
  ];

  const columns: GridColDef[] = [
    {
      field: 'no',
      headerName: t('TableHeaderProjectNo'),
      minWidth: 100,
      renderCell: (params: GridRenderCellParams) => (
        // Put correct link here
        <a className={styles.link} href={params.row.link}>
          {params.value}
        </a>
      ),
      valueGetter: (value, row): any => {
        if (row.id === 'TOTAL') {
          return row.name;
        }
        return value;
      },
      colSpan: (value, row: any) => {
        if (row.id === 'TOTAL') {
          return 2;
        }
        return undefined;
      },
    },
    {
      field: 'name',
      headerName: t('TableHeaderProjectName'),
      minWidth: 250,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => (
        // Put correct link here
        <a className={styles.link} href={params.row.link}>
          {params.value}
        </a>
      ),
    },
    {
      field: 'completion',
      headerName: t('TableHeaderCompletionMeter'),
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => {
        if (params.id === 'TOTAL') {
          return '';
        }
        const value = parseInt(params.value as string, 10);
        return (
          value >= 0 && (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <LinearProgress
                className={getColor(value)}
                variant="determinate"
                value={Math.min(value, 100)}
                sx={{
                  position: 'absolute',
                  width: '100%',
                  height: '58%',
                  borderRadius: 3,
                  overflow: 'hidden',
                }}
              />
              <Typography sx={{ position: 'absolute', color: 'white' }}>{`${value}%`}</Typography>
            </Box>
          )
        );
      },
    },
    {
      field: 'customer',
      headerName: t('TableHeaderCustomer'),
      minWidth: 120,
      renderCell: (params: GridRenderCellParams) => (
        // Put correct link here
        <a className={styles.link} href={params.row.link}>
          {params.value}
        </a>
      ),
    },
    {
      field: 'projectManager',
      headerName: t('TableHeaderProjectManager'),
      minWidth: 120,
    },
    {
      field: 'accountManager',
      headerName: t('TableHeaderAccountManager'),
      minWidth: 120,
    },
    {
      field: 'type',
      headerName: t('TableHeaderProjectType'),
      minWidth: 130,
    },
    {
      field: 'category',
      headerName: t('TableHeaderProjectCategory'),
      minWidth: 130,
    },
    {
      field: 'stage',
      headerName: t('TableHeaderProjectStage'),
      minWidth: 130,
    },
    {
      field: 'budgetProject',
      headerName: t('TableHeaderProjectBudgetHours'),
      minWidth: 120,
      headerAlign: 'right',
      align: 'right',
      headerClassName: `${dataGridCellBorderLeftThickClassName}`,
      cellClassName: `${dataGridCellBorderLeftThickClassName}`,
      renderCell: (params) => formatTime(params.value, siteLocale),
    },
    {
      field: 'budgetTask',
      headerName: t('TableHeaderTaskBudgetHours'),
      minWidth: 120,
      headerAlign: 'right',
      align: 'right',
      renderCell: (params) => formatTime(params.value, siteLocale),
    },
    {
      field: 'budgetAlloc',
      headerName: t('TableHeaderAllocatedBudgetHours'),
      minWidth: 120,
      headerAlign: 'right',
      align: 'right',
      headerClassName: `${dataGridCellBorderRightThickClassName}`,
      cellClassName: `${dataGridCellBorderRightThickClassName}`,
      renderCell: (params) => formatTime(params.value, siteLocale),
    },
    {
      field: 'actual',
      headerName: t('TableHeaderActualHours'),
      minWidth: 120,
      headerAlign: 'right',
      align: 'right',
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => (
        <Box position="relative">
          {params.id !== 'TOTAL' && (
            <IconButton
              className={classNames(styles.cornerInfo, {
                compact: density === 'compact',
                comfortable: density === 'comfortable',
              })}
              title={t('ViewTimeRegistrationText')}
              onClick={() => setShowTimeRegistration(true)}
            />
          )}
          <Typography>{formatTime(params.value, siteLocale)}</Typography>
        </Box>
      ),
    },
    {
      field: 'action',
      headerName: '',
      type: 'actions',
      minWidth: 130,
      maxWidth: 130,
      // eslint-disable-next-line react/no-unstable-nested-components
      getActions: (params: GridRowParams) => {
        if (params.id === 'TOTAL') {
          return [];
        }
        return [
          <GridActionsCellItem
            icon={
              <Tooltip title={t('EditProjectText')}>
                <Edit />
              </Tooltip>
            }
            label={t('EditProjectText')}
            onClick={() => alert('edit')}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={t('EditProjectSettingsText')}>
                <EditNote />
              </Tooltip>
            }
            label={t('EditProjectSettingsText')}
            onClick={() => alert('edit projects')}
          />,
          <GridActionsCellItem
            icon={
              <Tooltip title={t('DeleteProjectText')}>
                <Delete />
              </Tooltip>
            }
            label={t('DeleteProjectText')}
            onClick={() => alert('delete')}
          />,
        ];
      },
    },
    {
      field: 'status',
      headerName: '',
      type: 'singleSelect',
      valueOptions: statusValueOptions,
      minWidth: 130,
      maxWidth: 130,
      editable: true,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) => {
        const statusOption = statusValueOptions.find((option) => option.value === params.value);
        const Icon = statusOption ? statusOption.icon : null;
        return (
          <Box display="flex" alignItems="center">
            {Icon ? <Icon /> : null}
            <Typography sx={{ ml: 1 }}>{statusOption?.label}</Typography>
          </Box>
        );
      },
    },
    {
      field: 'bookingInfo',
      headerName: '',
      minWidth: 55,
      maxWidth: 55,
      display: 'flex',
      renderCell: (params: GridRenderCellParams) =>
        params.value ? (
          <IconButton title={t('PendingBookingInfo')} onClick={() => setShowBookingAction(true)}>
            <InfoOutlined fontSize="small" />
          </IconButton>
        ) : null,
    },
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'informationGroup',
      headerName: '',
      children: [
        { field: GRID_CHECKBOX_SELECTION_FIELD },
        { field: 'no' },
        { field: 'name' },
        { field: 'completion' },
        { field: 'customer' },
        { field: 'projectManager' },
        { field: 'accountManager' },
        { field: 'type' },
        { field: 'category' },
        { field: 'stage' },
        { field: 'actual' },
        { field: 'action' },
        { field: 'status' },
        { field: 'bookingInfo' },
      ],
    },
    {
      groupId: 'budget',
      headerName: t('TableHeaderBudget'),
      headerAlign: 'center',
      children: [{ field: 'budgetProject' }, { field: 'budgetTask' }, { field: 'budgetAlloc' }],
    },
  ];

  const pinnedRows = useMemo(
    () => ({
      bottom: [
        {
          id: 'TOTAL',
          name: 'Total',
          budgetProject: '10600.00',
          budgetTask: '205000.10',
          budgetAlloc: '40500.02',
          actual: '50100.00',
        },
      ],
    }),
    [],
  );

  // Height of the table
  const dataGridContainerRef = useRef<HTMLDivElement>(null);
  const [offSetTop, setOffSetTop] = useState<number>(0);

  // Set on initial load
  useEffect(() => {
    if (dataGridContainerRef.current) {
      setOffSetTop(dataGridContainerRef.current.offsetTop);
    }
  }, [isSuccess]);

  const listener = (ref: HTMLDivElement | null) => {
    if (ref) {
      setTimeout(() => {
        setOffSetTop(ref.offsetTop);
      }, 1000);
    }
  };

  // Set on FilterContainer toggle
  useEffect(() => {
    const ref = dataGridContainerRef.current;
    window.addEventListener(toggleFilterContainerEventName, () => listener(ref));

    return () => {
      window.removeEventListener(toggleFilterContainerEventName, () => listener(ref));
    };
  }, [dataGridContainerRef]);

  return (
    <ResponseHandler isLoading={isLoading} isEmpty={isEmpty} isError={isError}>
      <div
        style={{ width: '100%', height: `calc(100vh - ${offSetTop + 20}px)` }}
        ref={dataGridContainerRef}
      >
        <DataGrid
          columns={columns}
          rows={rows}
          disableColumnMenu
          hideFooter
          checkboxSelection
          disableRowSelectionOnClick
          pinnedRows={pinnedRows}
          onRowSelectionModelChange={(newSelection) => handleRowSelectionChange(newSelection)}
          slots={slots(isRowSelected, columns)}
          disableVirtualization
          columnGroupingModel={columnGroupingModel}
          density={density}
          onDensityChange={(newDensity) => setDensity(newDensity)}
          sx={{
            // Allow long text to wrap in the header
            '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
              lineHeight: 'normal',
            },
          }}
          data-automation-id="SearchProjectsTable"
        />
        {/* Action Dialog */}
        <TimeRegistrationsDialog
          showTimeRegistration={showTimeRegistration}
          setShowTimeRegistration={setShowTimeRegistration}
        />

        <BookingActionDialog
          showBookingAction={showBookingAction}
          setShowBookingAction={setShowBookingAction}
        />
      </div>
    </ResponseHandler>
  );
};
