import { useCallback, useEffect, useMemo, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { FilterLayout } from 'src/components/layout/FilterLayout';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { IMenuItem } from 'src/reducers/FilterReducer/FilterReducer';
import { InfoOutlined, Search, ContentCopyRounded, Delete, Edit } from '@mui/icons-material';
import { safeParseJson } from 'src/utils/object';
import {
  ISavedView,
  ISavedViewForm,
  SnackbarMode,
  TFormDialogMode,
  TTextDialogMode,
  TSavedViewAction,
} from 'src/apis/savedViewAPI';
import { v4 as uuidv4 } from 'uuid';

import { ToastifyAlert } from 'src/components/mui-components';
import { TextField, InputAdornment, Box, Tooltip } from '@mui/material';
import { useGetShortNameWithoutUrl } from 'src/apis/indexAPI';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';
import { toast } from 'react-toastify';
import { IFilterItemProperties } from 'src/apis/types/filterListAPI';
import { useGetFirstName, useGetUserId } from 'src/apis/usersAPI/meAPI';
import { useGetDropdownWithSearchAPI } from 'src/apis/dropdownAPI/get';
import {} from 'src/apis/searchProjectsAPI';
import { Spinner } from 'src/components/ui-components';
import { INITIAL_SAVED_VIEW, SNACKBAR_CONFIG } from './constants';
import {
  GetSPSavedViewsKey,
  SPSelectedFilterListStateKey,
  SPViewOptionsStateKey,
  SPFilterIsOpen,
} from './localStorageKeys';

import { sortViews } from '../ResourcePlanner/helper/sortViews';
import { IOptionsChangeParameters, TSnackbarKeys } from './types/searchProjects';

import { ViewFormDialog, ViewTextDialog } from './components/SavedViewDialog';
import { Table } from './components/Table';

export const SearchProjects = () => {
  const { t: tSearchProjects } = useTranslation('searchProjects');
  const { t } = useTranslation('savedView');

  localStorage.setItem(SPFilterIsOpen, 'true');
  const { shortNameWithoutUrl } = useGetShortNameWithoutUrl();
  const localStorageSavedViews =
    localStorage.getItem(GetSPSavedViewsKey(shortNameWithoutUrl)) ?? '';
  const [savedViews, setSavedViews] = useState<ISavedView[]>([]);

  // TO BE REMOVE, For mock purpose
  // const pageIdentifier = useGetCurrentPageIdentifier();
  const pageIdentifier = 'SearchProject';
  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);

  const [spLoading, setSpLoading] = useState(true);

  // Patch for no saved views
  const flattenedFilterItemsList = filterList.reduce(
    (a, { filterItems }) => [...a, ...(filterItems ?? [])],
    [] as IFilterItemProperties[],
  );
  const employeeFilter = flattenedFilterItemsList.find(({ name }) => name === 'Employee');
  const { firstName } = useGetFirstName();
  const { dropdownList } = useGetDropdownWithSearchAPI({
    key: `filterInput${employeeFilter?.id}`,
    MSWKey: `FILTER_INPUT_${employeeFilter?.id}`,
    path: `/${employeeFilter?.contentUrl}?pageIdentifier=${pageIdentifier}`,
    searchText: firstName,
    enabled: !!employeeFilter?.contentUrl && !savedViews.length,
  });
  const { userId } = useGetUserId();
  const currentUserOption = dropdownList?.find((item) => item.value === String(userId));
  // Patch for no saved views

  const sortedViews = useMemo((): ISavedView[] => sortViews(savedViews), [savedViews]);
  const [activeView, setActiveView] = useState<string>();

  const savedViewOptions = sortedViews?.find((v) => v.id === activeView)?.viewOptions;
  const [changedViewOptions, setChangedViewOptions] = useState<any | undefined>(
    savedViewOptions ?? undefined,
  );
  const initialFilter = useMemo(() => {
    if (!currentUserOption || !employeeFilter) {
      return undefined;
    }

    return {
      Employee: {
        label: employeeFilter.name,
        values: [
          {
            label: currentUserOption.label,
            value: currentUserOption.value,
          },
        ],
        contentUrl: employeeFilter.contentUrl,
        childFilters: employeeFilter.childFilters,
        parentFilters: employeeFilter.parentFilters,
        isInclude: true,
        type: employeeFilter.type,
      },
    };
  }, [currentUserOption, employeeFilter]);

  const updateSavedViews = useCallback(
    (sv: ISavedView[]) => {
      setSavedViews(() => {
        localStorage.setItem(GetSPSavedViewsKey(shortNameWithoutUrl), JSON.stringify(sv));
        return sv;
      });
    },
    [shortNameWithoutUrl],
  );

  const onViewOptionsChange = (optionItems: IOptionsChangeParameters[]) => {
    const options = optionItems.map((item) => ({ [item.name]: item.value }));
    const optionsToObject = Object.assign({}, ...options);

    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  const [showFormDialog, setShowFormDialog] = useState<TFormDialogMode | ''>('');
  const [showTextDialog, setShowTextDialog] = useState<TTextDialogMode | ''>('');

  const displayToast = (type: SnackbarMode) => {
    const config = SNACKBAR_CONFIG.find((c) => c.type === type);
    const { title, description } = config ?? {};
    toast.success(
      <ToastifyAlert
        title={t(title as TSnackbarKeys)}
        description={t(description as TSnackbarKeys)}
      />,
      {
        autoClose: 5000,
        closeButton: false,
      },
    );
  };

  // region Save View Form
  const formSchema = z.object({
    title: z.string().min(1, { message: 'Please enter title' }),
    isDefault: z.boolean(),
  });

  const form = useForm({
    defaultValues: {
      title: '',
      isDefault: false,
    } as ISavedViewForm,
    resolver: zodResolver(formSchema),
  });

  const menuItems: IMenuItem[] = [
    {
      id: 'edit',
      label: t('UpdateViewMenuText'),
      icon: <Edit />,
      action: () => {
        const viewIndex = sortedViews.find((v) => v.id === activeView);
        if (viewIndex) {
          form.setValue('title', viewIndex.title, { shouldDirty: true, shouldValidate: true });
          form.setValue('isDefault', viewIndex.isDefault);
          setShowFormDialog('edit');
        }
      },
    },
    {
      id: 'duplicate',
      label: t('DuplicateViewMenuText'),
      icon: <ContentCopyRounded />,
      action: () => {
        const viewIndex = sortedViews.find((v) => v.id === activeView);
        if (viewIndex) {
          form.setValue('title', viewIndex.title, { shouldDirty: true, shouldValidate: true });
          form.setValue('isDefault', viewIndex.isDefault);
          setShowFormDialog('duplicate');
        }
      },
    },
    {
      id: 'delete',
      label: t('DeleteViewMenuText'),
      icon: <Delete />,
      action: () => {
        setShowTextDialog('delete');
      },
    },
  ];

  const updateActiveView = (viewId?: string) => {
    setActiveView(() => {
      setSpLoading(true);
      return viewId;
    });
  };

  const onSubmit = (data: ISavedViewForm) => {
    // set default to false for all views
    const viewData: ISavedView[] = savedViews.map((view) => ({
      ...view,
      isDefault: data.isDefault ? false : view.isDefault,
    }));

    const filters = localStorage.getItem(SPSelectedFilterListStateKey);
    const newID = uuidv4();
    let updatedData: ISavedView[];

    switch (showFormDialog) {
      case 'save':
        if (!filters) {
          return;
        }

        updatedData = [
          ...viewData,
          {
            id: newID,
            title: data.title,
            isDefault: data.isDefault,
            createdDate: new Date(),
            filters: safeParseJson(filters),
          },
        ];
        displayToast('save');
        updateActiveView(newID);
        break;

      case 'edit':
        updatedData = viewData.map((view) => {
          if (view.id === activeView) {
            return {
              ...view,
              title: data.title,
              isDefault: data.isDefault,
            };
          }
          return view;
        });
        displayToast('edit');
        break;

      default:
        updatedData = [
          ...viewData,
          {
            id: newID,
            title: data.title,
            isDefault: data.isDefault,
            createdDate: new Date(),
            filters: filters ? safeParseJson(filters) : {},
          },
        ];
        displayToast('duplicate');
        updateActiveView(newID);
        break;
    }

    updateSavedViews(updatedData);
    form.reset();
  };

  const onDelete = () => {
    setShowTextDialog('');
    const updatedData = savedViews.filter((view) => view.id !== activeView);
    updateSavedViews(updatedData);
    updateActiveView(sortViews(updatedData)[0]?.id ?? '');
    displayToast('delete');
  };

  const onChangesUpdate = () => {
    const updatedSaveView = savedViews.map((v) => {
      const view = { ...v };
      if (view.id === activeView) {
        view.filters = safeParseJson(localStorage.getItem(SPSelectedFilterListStateKey) ?? '');
      }
      return view;
    });

    updateSavedViews(updatedSaveView);
    setShowTextDialog('');
    displayToast('changes');
  };

  const handleViewAction = (action: TSavedViewAction) => {
    if (action === 'saveAs') {
      form.reset();
      setShowFormDialog('save');
      return;
    }
    onChangesUpdate();
  };

  useEffect(() => {
    const localStorageViews = safeParseJson(localStorageSavedViews);
    if (!localStorageViews?.length) {
      updateSavedViews([
        {
          ...INITIAL_SAVED_VIEW,
          id: uuidv4(),
          title: t('InitialViewTitle'),
          filters: initialFilter,
        },
      ]);
      return;
    }
    setSavedViews(localStorageViews);
  }, [initialFilter, localStorageSavedViews, shortNameWithoutUrl, t, updateSavedViews]);

  useEffect(() => {
    if (activeView || !sortedViews?.length) {
      return;
    }
    updateActiveView(sortedViews[0].id);
  }, [activeView, sortedViews]);

  useEffect(() => {
    if (!savedViewOptions) {
      return;
    }
    setChangedViewOptions(savedViewOptions);
  }, [savedViewOptions]);

  useEffect(() => {
    if (!changedViewOptions) {
      return;
    }
    localStorage.setItem(SPViewOptionsStateKey, JSON.stringify(changedViewOptions));
    setSpLoading(false);
  }, [changedViewOptions]);

  return (
    <ResponseHandler isLoading={isLoading} isError={isError} isEmpty={filterList.length <= 0}>
      <FilterLayout
        filterList={filterList}
        selectedFilterList={sortedViews?.[0]?.filters}
        viewOptionsLeftFilter={
          <Box sx={{ width: 350 }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              size="small"
              fullWidth
              placeholder={tSearchProjects('FilterSearchPlaceholderText')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title={tSearchProjects('FilterFullTextSearchText')}>
                      <InfoOutlined />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              onBlur={(e) => onViewOptionsChange([{ name: 'search', value: e.target.value }])}
            />
          </Box>
        }
        localStorageNamePrefix="search-projects"
        allowSavedViews
        savedViewsList={sortedViews}
        activeView={activeView}
        menuItems={menuItems}
        handleViewAction={handleViewAction}
        setActiveView={updateActiveView}
      >
        {!spLoading ? <Table selectedViewOptions={changedViewOptions} /> : <Spinner />}

        {/* For form dialog */}
        {showFormDialog && (
          <ViewFormDialog
            showFormDialog={showFormDialog}
            setShowFormDialog={setShowFormDialog}
            onSubmit={onSubmit}
            form={form}
          />
        )}

        {/* For text alert dialog */}
        {showTextDialog && (
          <ViewTextDialog
            showTextDialog={showTextDialog}
            setShowTextDialog={setShowTextDialog}
            onDelete={onDelete}
          />
        )}
      </FilterLayout>
    </ResponseHandler>
  );
};
